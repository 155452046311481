body.dark-theme {
    background-color: #141d26 !important;
    color:	#c8d5e4;
    mix-blend-mode: difference;
}

.dark-mode-switch {
    mix-blend-mode: difference;
    cursor: pointer;
    width: 30px;
    height: 30px;
    position: absolute;
    right: 15px;
    top: 15px;
    background-color: #222527;
    border-radius: 0.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dark-mode-switch:before {
    content: '\f186';
    font-family: fontAwesome;
    color: #c8d5e4;
}

body.dark-theme .dark-mode-switch:before {
    content: '\f185';
    font-family: fontAwesome;
    color: #c8d5e4;
}
